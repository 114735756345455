import * as React from "react"
import { graphql, PageProps } from "gatsby"
import Layout from "../components/layout"
import { StoresPageQuery, Store } from "../graphql-types"
import StoreList from "../partials/store/store-list"
import Container from "../components/container"
import useLocations from "../hooks/use-locations"
import useVarieties from "../hooks/use-varieties"
import useChains from "../hooks/use-chains"

export default function StoresPage({ data }: PageProps<StoresPageQuery>) {
  const locations = useLocations()
  const varieties = useVarieties()
  const chains = useChains()

  return (
    <Layout title={"Vintage stores in Japan"}>
      <Container className={"justify-center"}>
        <StoreList
          locations={locations}
          chains={chains}
          varieties={varieties}
          stores={data.allStore.nodes as Store[]}
        />
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query StoresPage {
    allStore {
      nodes {
        ...StoreCommon
        ...StoreLocation
        ...StoreVarieties
        ...StoreTileImages
        ...StoreChain
      }
    }
  }
`
